<!--
    组件说明:
           propertys:   exts(可选) 限定文件类型['json']
                        maxsize(可选) 最大大小(MB)
           events:      done   文件筛选没问题回调
           slot:        default: 按钮名字
-->
<template>
            <el-upload
                    action=""
                    ref="select"
                    :limit="1"
                    :on-change="fileChange"
                    :auto-upload="false">
                <el-button slot="trigger" size="medium" type="primary">
                    <slot>选择文件</slot>
                </el-button>
            </el-upload>


</template>

<script>
    export default {
        data(){
            return {
            }
        },
        props:{
            exts:{
                type: Array,
                default: function () {
                    return ['json'];
                },
            },
            maxsize:{
                type: Number,
                default: 5  //不超过5M
            }
        },
        methods:{
            // 文件改变时
            fileChange(file) {
                this.$refs.select.clearFiles()
                if(this.checkSelect(file)){
                    this.$emit('done', file)
                }
            },
            checkSelect(file){
                const fileName = file.name;
                const arr = fileName.split('.');
                const extension = arr[arr.length-1];
                if(!this.exts.includes(extension)){
                    this.$message.warning(`选择文件只能是 ${this.exts.join('、')}格式!`)
                    return false
                }
                const isLt5M = file.size / 1024 / 1024 < this.maxsize
                if (!isLt5M) {
                    this.$message.warning(`选择文件大小不能超过 ${this.maxsize}MB!`)
                    return false
                }
                return true;
            },
        }
    }
</script>

<style scoped>

</style>